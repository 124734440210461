.MuiTableRow-root > * {
  padding: 10px;
}

.MuiTableRow-root.MuiTableRow-head > * {
  font-weight: bold !important;
}

.Table td,
th {
  border: none !important;
}

.Table:first-child {
  border-radius: 0.7rem !important;
}
.status {
  padding: 8px;
  border-radius: 9px;
}

.Details {
  color: #00b5ff !important;
}

@media screen and (max-width: 1200px) {
  .Table {
    width: 170%;
    margin-top: 2rem;
  }
}


@media screen and (max-width: 768px) {
  .Table{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 23rem;
  }
}