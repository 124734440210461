:root {
    --yellow: linear-gradient(180deg, #F8D49A -146.42%, #FAD79D -46.42%);
    --orange: #fca61f;
    --black: #242d49;
    --gray: #788097;
    --purple: linear-gradient(180deg, #BB67FF 0%, #C484F3 100%);
    --pink: #FF919D;
    --glass: rgba(255, 255, 255, 0.54);
    --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
    --activeItem: #f799a354;
  }
  
  .Dashboard {
    /* padding: 0.5rem 3.5rem; */
    color: var(--black);
    background: linear-gradient(
      106.37deg,
      #ffe1bc 29.63%,
      #ffcfd1 51.55%,
      #f3c6f1 90.85%
    );
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter', sans-serif;
  }
  
  .DashboardGlass {
    display: grid;
    height: 97%;
    width: 97%;
    background: var(--glass);
    border-radius: 2rem;
    gap: 16px;
    grid-template-columns: 11rem auto auto;
    overflow: hidden;
  }
  
  @media screen and (max-width: 1200px) {
    .DashboardGlass{
      grid-template-columns: 10% 50% auto;
      overflow-y: scroll;
    } 
  }
  
  @media screen and (max-width: 768px) {
    .DashboardGlass{
      grid-template-columns: 1fr;
    } 
  }